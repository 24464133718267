import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function MakeImg({ croppedImage, setCroppedImage }) {
  const [isShowCrop, setIsShowCrop] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    aspect: 16 / 9,
    width: 80,
    height: 80,
    x: 0,
    y: 0,
  });

  // Kiểm tra chiều rộng màn hình
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUpload = () => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      const cropX = crop.x * scaleX * pixelRatio;
      const cropY = crop.y * scaleY * pixelRatio;
      const cropWidth = crop.width * scaleX * pixelRatio;
      const cropHeight = crop.height * scaleY * pixelRatio;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      canvas.toBlob((blob) => {
        const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
        setCroppedImage(file);
        setIsShowCrop(false);
      }, 'image/jpeg');
    };
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);

      if (isMobileView) {
        // Tải ảnh trực tiếp mà không cần cắt
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          canvas.toBlob((blob) => {
            const file = new File([blob], 'uploaded-image.jpg', { type: selectedFile.type });
            setCroppedImage(file);  // Đặt ảnh vào state croppedImage mà không cần cắt
          }, selectedFile.type);
        };
      } else {
        setIsShowCrop(true);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className='p-1'>
      <div className="d-flex justify-content-between">
        <input type="file" title='chọn ảnh' accept="image/*" onChange={handleFileSelect} multiple={false} />
      </div>
      <div className='mx-auto'>
        {croppedImage &&
          <div className="d-flex justify-content-center">
            <img src={URL.createObjectURL(croppedImage)} alt="" className='max-withMobileImg' />
          </div>
        }
        {isShowCrop && !isMobileView &&
          <div>
            <div className='d-flex justify-content-center m-1 p-1'>
              <button className='btn btn-secondary' onClick={handleUpload}>Cắt ảnh</button>
            </div>
            <div className='d-flex justify-content-center'>
              <ReactCrop 
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                aspect={16 / 9}
              >
                <img src={imageUrl} alt="" className='img-crop'/>
              </ReactCrop>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
