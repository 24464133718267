
export const UploadVideoToS3 = async (file, formState, setFormState, setIsLoading, setProcess) => {
    setIsLoading(true);
    
    if (!file) return;

    // Gửi request để lấy presigned URL
    try {
        
        const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_UPLOADS3+`/?fileName=${file.name}&fileType=${file.type}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Failed to get presigned URL');
        }

        const { url } = await response.json();

        // Tải file lên S3 bằng presigned URL
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.upload.onprogress = function (event) {
            var progress = Math.round((event.loaded / event.total) * 100);
            setProcess(progress);
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                setFormState({
                    ...formState,
                    url: url.split('?')[0], // Lấy URL của file sau khi tải lên
                });
                console.log('Video uploaded to S3:', url.split('?')[0]);
            } else {
                console.error('Upload error', xhr.responseText);
            }
        };

        xhr.send(file);
    } catch (error) {
        console.error('Error uploading video to S3:', error);
    } 
};
