import { useState } from 'react'
import React from 'react'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
export default function HeaderMid() {
  const [searchFilter, setSearchFilter] = useState('');
  const [isInputVisible, setInputVisible] = useState(false);
  const history = useHistory();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      history.push(`/videosSearch/${searchFilter}`);

    }
  };
  const toggleSearchInput = () => {
    setInputVisible(!isInputVisible);
  };
  return (
    <li className='nav-item flex-fill d-flex justify-content-center'>
      <form className="d-flex">
        <input 
          onChange={(e) => setSearchFilter(e.target.value)} 
          className={`form-control me-1 ${isInputVisible ? 'd-block' : 'd-none d-md-block'}`} 
          type="search" 
          placeholder="Tìm kiếm" 
          aria-label="Search"
          onKeyPress={handleKeyPress}
        />
        <Link 
          to={isInputVisible ? `/videosSearch/${searchFilter}` : '#'} 
          onClick={toggleSearchInput}
          className="btnAdd"
        >
          <i className="fas fa-search me-1" />
        </Link>
      </form>

      <style>{`
        @media (max-width: 768px) {
          .form-control {
            display: none;
          }
          .btnAdd {
            display: inline-block;
          }
        }
      }`}</style>

    </li>
    // <li className='nav-item flex-fill search-form ms-3'>
    //   <form className="d-flex ">
    //     <input 
    //       onChange={(e) => setSearchFilter(e.target.value)} 
    //       className="form-control me-1 " type="search" placeholder="Tìm kiếm" aria-label="Search"
    //       onKeyPress={handleKeyPress}
    //     />
    //     <Link to={"/videosSearch/"+searchFilter}
    //       className="btnAdd me-3" type="submit">
    //       <i className="fas fa-search" />
    //     </Link>
    //   </form>
    // </li>
  )
}
